@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    text-align: center;
    text-transform: uppercase;
    background-color: $white;
    padding: rem(20);
    background-image: url('../img/Background.jpg');
    background-size: cover;
    color: $black;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    @media screen and (max-width: 1100px){
        padding: 0;
    }
}

a{
    color: inherit;
    display: inline-block;
    font-family: $bold-font;
    border-bottom: 1px solid;
    line-height: .9;
    margin: 0 rem(10);
    
    @include hover{
        color: $primary-color;
    }
}


.page-wrap{
    width: 100%;
    max-width: rem(1350);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 6px solid #ffffe7;
    border-right: 6px solid #c4ad74;
    @media screen and (min-width:1101px){
        position: relative;
        height: calc(100vh - 40px);
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
        padding-bottom: rem(40);
    }
    @media screen and (max-width:1100px){
        border-top: 6px solid #d6c697;
        border-bottom: 6px solid #d6c697;

        border-image: linear-gradient(to right,#ffffe7, #c4ad74);
        border-image-slice: 1;
    }

    .bar{
        display: flex;
        
        @media screen and (min-width:1101px){
            position: fixed;
            z-index: 10;
            left: 0;
            right: 0;
            &:first-child{
            top: 20px;
            }
            &:last-child{
                bottom: 20px;
            }
            
            width: calc(100% - 40px);
            max-width: rem(1350);
            margin: 0 auto;
        }
        &#footer{
            align-items: flex-end;
        }
        .space{
            flex: 1;
            @media screen and (min-width:1101px){
                min-width: rem(30);
                height: 6px;
                background-color: $gray;
                &:nth-child(1){
                    background-image: linear-gradient(to right,#ffffe7, #ede6c5);
                }
                &:nth-child(3){
                    background-image: linear-gradient(to right,#d6c697, #c4ad74);
                }
            }
        }
    }
}

ul.social{
    transform: translateY(-40%);
    padding: 0 rem(20);
    font-size: rem(20);
    li{
        display: inline-block;
        margin: 0 1em 0;
        color: $primary-color;
    }
    a{
        border: none;
        margin: 0;
        @include hover{
            color: $black;
        }
    }
    @media screen and (max-width:1100px){
        transform: none;
        margin: rem(30) 0 rem(20);
        font-size: rem(20);
        li{
            margin: 0 .3em;
        }
    }
}

.drag{
    display: block;
    margin: 0 auto;
}

.img-car{
    width: rem(244);
    @media screen and (max-width: 1100px){
        margin: rem(30) auto;
    }
}

.img-bench{
    width: rem(200);
    @media screen and (max-width: 1100px){
        margin: rem(20) auto;
    }
}

.img-tomato{
    width: rem(140);
    @media screen and (max-width: 1100px){
        margin: rem(15) auto rem(30);
    }
}

.img-bottle{
    width: rem(256);
    @media screen and (max-width: 1100px){
        margin: rem(30) auto;
    }
}

.logo-text{
    width: 100%;
    max-width: rem(240);
    margin: rem(20) 0;
    &.wide{
        max-width: rem(290);
        @media screen and (min-width: 1101px){
            margin: rem(50) 0;
            max-width: rem(550);
        }
    }
}

#header{
    @media screen and (min-width: 1101px){
        .container{
            display: flex;
            min-height: 95vh;
        }
        .col{
            flex: 1;
            flex-basis: rem(300);
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            &:nth-child(1){
                order: 2;
                flex-basis: rem(500);
            }
            &:nth-child(3){
                order: 3;
            }
        }
    }
    .col:first-child{
        .text-block{
            font-size: rem(25);
        }
    }
}


.text-block{
    font-size: rem(15);
    h1{
        font-size: rem(25);
        font-weight: normal;
        font-family: $bold-font;
        @media screen and (max-width: 1100px){
            font-size: rem(35);
        }
    }
    p{
        margin: rem(10) 0;
    }
    .date{
        font-size: rem(20);
        margin: 0;
    }
    .title{
        font-size: rem(20);
        font-weight: normal;
        font-family: $bold-font;
        margin: rem(15) 0 rem(5);
    }
    .note{
        font-size: rem(10);
        letter-spacing: .03em;
    }
    @media screen and (max-width:1100px){
        .btns{
            display: flex;
            align-items: center;
            flex-direction: column;
            a{
                margin: 0 0 rem(10);
            }
        }
    }
}

h2{
    color: $primary-color;
    margin: 1em auto;
    font-size: rem(22);
    font-family: $bold-font;
    font-weight: normal;
    margin: rem(40) 0;
    @media screen and (max-width:1100px){
        margin: 0 auto 1em;
        width: rem(260);
    }
}
#dates{
    margin: 0 auto;
    width: 100%;
    max-width: rem(560);
    font-size: rem(15);
    
    .event{
        margin: 1.5em 0;
        display: flex;
        text-align: left;
        >div{
            flex: 1;
            &.date, &.tickets{
                flex: .5;
            }
            &.location{
                font-family: $bold-font;
            }
            &.tickets{
                text-align: right;
            }
        }
        .sold-out{
            display: inline-block;
            font-family: $bold-font;
            border-bottom: 1px solid;
            line-height: .9;
            margin: 0 rem(10);
            color: #DB0000
        }

        
        @media screen and (max-width:1100px){
            flex-direction: column;
            text-align: center;
            >div.tickets{
                text-align: center;
            }
        }
    }
}

.dropdown-wrap{
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    max-width:rem(120);
    height: rem(14);
    position: relative;
    top: -2px;
    ul{
        position: absolute;
        left: 0;
        bottom: top;
        z-index: 9;
    }
    li{
        display: none;
        margin: 0;
        background-color: #e5e5e5;
        opacity: 0;
        transition: .3s all;
        &:first-child{
            text-align: left;
            display: block;
            background-color: transparent;
            height: auto;
            opacity: 1;
            padding: 0;
            a{
                padding: 0;
                margin: 0 rem(10);
            }
        }
    }
    &.open{
        ul{
            position: absolute;
        }
        li{
            display: block;
            opacity: 1;
            width: rem(250);
            a{
                width: 100%;
                background-color: $white;
                padding: rem(10);
                margin: 0;
            }
            &:first-child{
                display: block;
                background-color: transparent;
                height: auto;
                padding: 0;
                a{
                    width: auto;
                    padding: 0;
                    margin: 0 rem(10) rem(10);
                    background-color: transparent;
                }
            }
        }
        
    }
}

#footer{
    .copyright{
        text-align: center;
        padding: 0 rem(20);
        margin: 0;
        transform: translateY(50%);
        color: $primary-color;
        font-size: rem(9);
        text-transform: uppercase;
        a{
            border: none;
            margin: 0;
            @include hover{
                color: $black;
            }
        }
        @media screen and (max-width:1100px){
            transform: none;    
            padding: 0;
            margin-bottom: rem(10);
        }
    }
}

.newsletter{
    margin-top: rem(60);
    font-size: rem(15);
    text-transform: uppercase;
    button{
        display: inline-block;
        font-family: $bold-font;
        border-bottom: 1px solid;
        line-height: 1;
        padding: 0;
        margin-left: rem(2);
        @include hover{
            color: $primary-color;
        }
    }
    input{
        border-bottom: 1px solid $black;
        text-transform: uppercase;
        color: $black;
        padding: 0;
        &::placeholder{
            color: $black;
        }
    }
    @media screen and (max-width: 1100px){
        margin: rem(30) 0;
    }
}

.popup{
    width: 100%;
    max-width: rem(500);
    position: relative;
    background-color: $white;
    color: $black;
    text-align: center;
    margin: rem(30) auto;
    padding: rem(30);

    p{
        font-size: rem(31);
    }
    #apple_music_form{
        margin-left: rem(15);
        display: inline-block;
        vertical-align: middle;
        font-size: rem(15);
        letter-spacing: .05em;
        @media screen and (max-width: 520px){
            margin: rem(25) auto 0;
            display: block;
        }
        input{
            border-bottom: 2px solid $black;
            text-align: center;
            font-size: rem(18);
        }
        .btn{
            background-color: $black;
            color: $white;
            border-radius: rem(15);
            padding: rem(2) rem(10);
            @include hover{
                background-color: $primary-color;
            } 
        }
    }
}

@media screen and (max-width: 1100px){
    .desktop{
        display: none !important;
    }
}

@media screen and (min-width: 1101px){
    .mobile{
        display: none !important;
    }
}