//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #CFB66B;
$gray: #707070;

//Fonts
$primary-font: "Trade Gothic LT W05 Cond No-_1", sans-serif;
$bold-font: "Trade Gothic LT W05 Bd Cn No-2", sans-serif;
